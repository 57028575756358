<template>
    <div class="add-new-travelling-order" v-if="invoice">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.invoiceUpdate')"
            :title="'Update invoice - ' + invoice.invoiceNumber"
            @submitPressed="submitPressed"
        />
        <invoice-form
            v-if="invoice"
            :invoiceObject="invoice"
            :action="action"
            @clearAction="action = null"
        />
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import InvoiceForm from "./components/InvoiceForm.vue";

export default {
    components: {
        TitleBar,
        InvoiceForm,
    },
    data() {
        return {
            invoice: null,
            action: null,
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const id = this.$route.params.id;
            this.$Invoices.getResource({id}).then((response) => {
                this.invoice = response.data;
            });
        },
        submitPressed() {
            this.action = "update";
        },
    },
};
</script>
